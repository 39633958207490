import styled from 'styled-components'
import { motion } from "framer-motion"
import image1 from "../Assets/storelearning/6.png"
import image2 from "../Assets/storelearning/7.png"
import image3 from "../Assets/storelearning/8.png"
import image4 from "../Assets/storelearning/9.png"
import image5 from "../Assets/storelearning/10.png"
import image6 from "../Assets/storelearning/12.png"
import { useContext } from 'react'
import { DataContext } from '../Contexts/DataContext'
import { Parallax } from 'react-scroll-parallax'
import { useState } from 'react'
import { useEffect } from 'react'


const Project = styled(motion.div)`
    width: 80%;
    display: flex;
    align-items: center;
    // justify-content: center;
    margin: auto;
    min-height: 100%;
    flex-direction: column;

    @media (max-width: 600px) {
        width: 90%;
       }

`

const Header = styled(motion.div)`
    padding: 30px;
    text-transform: uppercase;
    background: linear-gradient(to right, #b12a5b 0%, #ff867a 15%, #ff8c7f 5%, #f99185 70%, #cf556c 85%, #b12a5b 100%);;
    background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 1.5px;

    @media (max-width: 600px) {
        font-size: var(--fs);
        line-height: 1.5;
        letter-spacing: 0.5px;
        text-transform: capitalize;
        padding: 5px;
       }

`

const Body = styled(motion.div)`
    height: 100%;
    width: 100%;
    display: flex;
    // align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    gap: 20px;
`

const H5 = styled(motion.div)`
    text-transform: uppercase;
    margin: 20px;
    background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 1px;

    @media (max-width: 600px) {
        font-size: var(--fs);
        letter-spacing: 0.5px;
       }

`
const Para = styled(motion.div)`
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-indent: 50px;
    line-height: 2;
    letter-spacing: 0.5px;

    @media (max-width: 600px) {
        font-size: var(--fs);
        letter-spacing: 0.5px;
        text-indent: 20px;
        font-size: 12px;
    }

`
const Next = styled(motion.div)`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`
const Button = styled(motion.div)`
background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);    color: black;
    width: max-content;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;

    @media (max-width: 600px) {
        font-size: var(--fs);
        font-size: 10px;
    }

`

const Imgcontainer = styled(motion.div)`
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 600px) {
        flex-direction: column;
    }

`

const Image = styled(motion.div)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`


const Project2 = () => {

    const { setSelected } = useContext(DataContext)

    // const [height, setHeight] = useState("600px")
    // const [width, setWidth] = useState("1000px")
    const [speed1, setSpeed1] = useState(0)
    const [speed2, setSpeed2] = useState(100)



    useEffect(() => {
        if (window.innerWidth < 600) {
            setSpeed1(20)
            setSpeed2(50)
        }
    }, [])


    const changeSelected = () => {
        setSelected(3)
        window.location.replace("/#projects")
    }


    return (
        <Project>
            <Header>
                2. Store Learning App
            </Header>
            <Body>

                <Parallax
                    translateX={[20, -50]}
                    scale={[0.75, 1.5]}
                    easing="easeInQuad"
                >

                    <Image>
                        <img style={{
                            height: "600px", width: "1000px", borderRadius: "10px", objectFit: "contain", objectPosition: "center",

                        }} src={image1} alt="loading..." />
                    </Image>

                </Parallax>

                <H5
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    Client
                </H5>
                <Para
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    Go Colors is a 5400 Cr market cap womens bottom wear brand
                </Para>
                <H5
                    initial={{ opacity: 0, x: 20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    Requirement
                </H5>
                <Para
                    initial={{ opacity: 0, x: 20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    The brand required an app to keep the staffs up-to-date about the store inventory and product knowledge at their brick and mortar stores
                </Para>

                <Parallax
                    translateX={[-20, 50]}
                    scale={[0.75, 1]}
                    easing="easeInQuad"
                >

                    <Image>
                        <img style={{
                            height: "600px", width: "1000px", borderRadius: "10px", objectFit: "contain", objectPosition: "center",

                        }} src={image2} alt="loading..." />
                    </Image>

                </Parallax>

                <H5
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    Tech Stack
                </H5>
                <Para
                    initial={{ opacity: 0, x: 20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    React | PWA | MongoDB | Google Cloud Platform | Nodejs
                </Para>


                <Imgcontainer>

                    <Parallax
                        translateX={[-speed1, speed2]}
                        scale={[0.75, 1]}
                        easing="easeInQuad"
                    >

                        <Image>
                            <img style={{
                                height: "600px", width: "450px", borderRadius: "10px", objectFit: "contain", objectPosition: "center",

                            }} src={image3} alt="loading..." />
                        </Image>

                    </Parallax>

                    <Parallax
                        translateX={[speed1, -speed2]}
                        scale={[0.75, 1]}
                        easing="easeInQuad"
                    >

                        <Image>
                            <img style={{
                                height: "600px", width: "450px", borderRadius: "10px", objectFit: "contain", objectPosition: "center",

                            }} src={image4} alt="loading..." />
                        </Image>

                    </Parallax>

                </Imgcontainer>

                <Imgcontainer>

                    <Parallax
                        translateX={[-speed1, speed2]}
                        scale={[0.75, 1]}
                        easing="easeInQuad"
                    >

                        <Image>
                            <img style={{
                                height: "600px", width: "450px", borderRadius: "10px", objectFit: "contain", objectPosition: "center",

                            }} src={image5} alt="loading..." />
                        </Image>

                    </Parallax>

                    <Parallax
                        translateX={[speed1, -speed2]}
                        scale={[0.75, 1]}
                        easing="easeInQuad"
                    >

                        <Image>
                            <img style={{
                                height: "600px", width: "450px", borderRadius: "10px", objectFit: "contain", objectPosition: "center",

                            }} src={image6} alt="loading..." />
                        </Image>

                    </Parallax>

                </Imgcontainer>

                <Next onClick={() => changeSelected()}>
                    <Button>
                        Next: Re-Ordering tool
                    </Button>
                </Next>

            </Body>
        </Project>
    )
}

export default Project2