import styled from "styled-components"
import { motion } from "framer-motion";
import { Parallax } from "react-scroll-parallax";



const Container = styled(motion.div)`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Inner = styled(motion.div)`
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
      flex-direction: column;
    }
`

const Left = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: space-around;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  margin: 20px 0px;

  @media (max-width: 600px) {
    width: 98%;
    padding: 20px 5px;
  }

`
const H2 = styled(motion.div)`
    font-size: var(--fl);
    padding: 20px;
    width: max-content;
    color: #affc41;
    background: linear-gradient(to right, transparent 50%, #affc41 50%);
    background-size: 200% 100%;
    background-position: right;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center; 
    letter-spacing: 2px; 

    @media (max-width: 600px) {
      font-size: var(--fm);
      padding: 10px 20px;
      letter-spacing: 1px; 

`

const H5 = styled(motion.div)`
  display: flex;
  // align-items: center;
  // justify-content: center;
  color: white;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  padding: 20px 40px;
//   flex-direction: column;
  line-height: 2;
  letter-spacing: 0.5px; 
  // background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  //       -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;

  @media (max-width: 600px) {
    padding: 10px;
  }


`

const Span = styled(motion.div)`
  padding: 5px 10px;
  color: #d7e3fc;
  background: ${props => props.val};
  background-size: 200% 100%;
  background-position: right;
  display: flex;
  align-items: center;
  justify-content: center; 
  min-height: 100px;
//   background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%);
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
  width: 150px;
  border: 1px solid #0496ff;
  border-radius: 3px;
  font-size: 14px;
  background-color: rgba(4,150,255, 0.15);

  @media (max-width: 600px) {
    font-size: 12px;
  }

`

const Services = () => {


    return (
        <Container>
            <Inner>
                <Left>
                    <H2
                        initial={{ opacity: 0, backgroundColor: "#affc41", backgroundPosition: "100%" }}
                        whileInView={{ opacity: 1, backgroundColor: "transparent", backgroundPosition: "0%" }}
                        transition={{ ease: [0, .48, 0, .86], duration: 0.5, delay: 0.2 }}
                    >
                        Services Offered
                    </H2>
                    <H5>
                        <Span>
                            Master Data Management
                        </Span>
                        <Span>
                            Omni Channel Commerce
                        </Span>
                        <Span>
                            POS / ERP Implementation
                        </Span>
                        <Span>
                            Workflow Automation
                        </Span>
                        <Span>
                            Reordering Tool
                        </Span>
                        <Span>
                            Inventory Management (B2B, B2C, D2C)
                        </Span>
                        <Span>
                            MIS / BI Consultation
                        </Span>
                        <Span>
                            Catalog Management
                        </Span>
                        <Span>
                            Warehouse Management - P2P Cycle
                        </Span>
                        <Span>
                            3P  / API Integrations
                        </Span>
                    </H5>
                </Left>
            </Inner>
        </Container >

    )
}

export default Services

