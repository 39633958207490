import styled from "styled-components"
import { Parallax } from "react-scroll-parallax";


const Container = styled.div`
    height: 100%;
    width: 100%;
    // background-color: #390099;
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;

    @media (max-width: 600px) {
        // gap: 5px;
        flex-wrap: wrap;
    }
  
`

const BrandAssociated = styled.div`
    height: 50px;
    width: 180px;
    padding: 20px;
    background-color: green;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: var(--fl);

    @media (max-width: 600px) {
        font-size: 12px;
        height: 30px;
        width: 100px;
    }


`
const Rubans = styled.div`
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #03045e;
    font-size: var(--fs);
    background-color: #ffcdb2;
    background-image: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
    padding: 20px;
    width: 180px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 2px;

    @media (max-width: 600px) {
        font-size: 12px;
        height: 30px;
        width: max-content;
    }

`
const SafeHarvest = styled.div`
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
    color: #03045e;
    font-size: var(--fs);
    padding: 20px;
    width: 180px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 2px;

    @media (max-width: 600px) {
        font-size: 12px;
        height: 30px;
        width: max-content;
    }

`


const Soch = styled.div`
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.25) 200%);
    background-blend-mode: multiply;
    color: white;
    font-size: var(--fs);
    padding: 20px;
    width: 180px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 2px;

    @media (max-width: 600px) {
        font-size: 12px;
        height: 30px;
        width: max-content;
    }


`
const Ariro = styled.div`
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
    color: #03045e;
    font-size: var(--fs);
    padding: 20px;
    width: 180px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 2px;

    @media (max-width: 600px) {
        font-size: 12px;
        height: 30px;
        width: max-content;
    }

`
const InfiniteScents = styled.div`
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    color: #03045e;
    font-size: var(--fs);
    padding: 20px;
    width: 180px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 2px;

    @media (max-width: 600px) {
        font-size: 12px;
        height: 30px;
        width: max-content;
    }


`
const GoColors = styled.div`
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
    color: #03045e;
    font-size: var(--fs);
    padding: 20px;
    width: 180px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 2px;

    @media (max-width: 600px) {
        font-size: 12px;
        height: 30px;
        width: max-content;
    }

`

const Brands1 = () => {
    return (
        <Container>

            <Parallax
                translateX={[120, -120]}
                scale={[0.75, 1]}
                easing="easeInQuad"
            >

                <BrandAssociated>
                    Brands Associated
                </BrandAssociated>
            </Parallax>

            <Parallax
                translateX={[100, -100]}
                scale={[0.75, 1]}
                easing="easeInQuad"
            >

                <Rubans>
                    Rubans Accessories
                </Rubans>
            </Parallax>

            <Parallax
                translateX={[100, -100]}
                scale={[0.75, 1]}
                easing="easeInQuad"
            >

                <SafeHarvest>
                    Safe Harvest
                </SafeHarvest>
            </Parallax>

            <Parallax
                translateX={[100, -100]}
                scale={[0.75, 1]}
                easing="easeInQuad"
            >

                <Soch>
                    Soch
                </Soch>
            </Parallax>

            <Parallax
                translateX={[100, -100]}
                scale={[0.75, 1]}
                easing="easeInQuad"
            >

                <Ariro>
                    Ariro Toys
                </Ariro>
            </Parallax>


            <Parallax
                translateX={[100, -100]}
                scale={[0.75, 1]}
                easing="easeInQuad"
            >

                <InfiniteScents>
                    Infinite Scents
                </InfiniteScents>
            </Parallax>

            <Parallax
                translateX={[100, -100]}
                scale={[0.75, 1]}
                easing="easeInQuad"
            >

                <GoColors>
                    Go Colors
                </GoColors>
            </Parallax>

        </Container>
    )
}

export default Brands1