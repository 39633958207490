/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 planes.gltf --transform
Author: Miroshkins (https://sketchfab.com/miroshkins)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/paper-plane-77a7e55aa20f4b6594ca385c7dcbeeaf
Title: Paper Plane
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/planes-transformed.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Plane_Material001_0.geometry} material={materials['Material.001']} position={[-43.63, 19.79, 0.89]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
    </group>
  )
}

useGLTF.preload('/planes-transformed.glb')
