import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'
import Homepage2 from "./Pages/Homepage2";
import { ParallaxProvider } from 'react-scroll-parallax';
import AuthContextProvider from './Contexts/DataContext';


function App() {
  return (
    <AuthContextProvider>
      <ParallaxProvider>
        <Router>
          <Routes>
            <Route exact path="/" element={<Homepage2 />} />
          </Routes>
        </Router>
      </ParallaxProvider>
    </AuthContextProvider>

  );
}

export default App;
