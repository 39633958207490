import React from 'react'
import styled from 'styled-components'
import { motion } from "framer-motion"
import Project1 from './Project1'
import Project2 from './Project2'
import Project3 from './Project3'
import { useContext } from 'react'
import { DataContext } from '../Contexts/DataContext'


const Container = styled(motion.div)`
    width: 100%;
    height: 100%;
    margin: 50px 0px;
`

const H2 = styled(motion.div)`
    font-size: var(--fl);
    padding: 20px;
    width: max-content;
    color: #affc41;
    margin-bottom: 30px;
    background: linear-gradient(to right, transparent 50%, #affc41 50%);
    background-size: 200% 100%;
    background-position: right;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center; 
    letter-spacing: 2px; 

    @media (max-width: 600px) {
        font-size: var(--fm);
        padding: 10px 20px;
        letter-spacing: 1px; 
    }
  
`


const Projects = () => {

    const { selected } = useContext(DataContext)


    return (
        <Container id="projects">
            <H2
                initial={{ opacity: 0, backgroundColor: "#affc41", backgroundPosition: "100%" }}
                whileInView={{ opacity: 1, backgroundColor: "transparent", backgroundPosition: "0%" }}
                transition={{ ease: [0, .48, 0, .86], duration: 0.5, delay: 0.2 }}
            >
                Projects
            </H2>

            {selected === 1 && <Project1 />}
            {selected === 2 && <Project2 />}
            {selected === 3 && <Project3 />}

        </Container>
    )
}

export default Projects