import styled from "styled-components"
import { motion } from "framer-motion";
import html from "../Assets/tools/html.png"
import react from "../Assets/tools/react.png"
import css from "../Assets/tools/css.png"
import js from "../Assets/tools/js.png"
import python from "../Assets/tools/python.png"
import chart from "../Assets/tools/chart.png"
import { DiNodejs } from "react-icons/di"
import { RiFileExcel2Line } from "react-icons/ri"
import { Parallax } from "react-scroll-parallax";



const Container = styled(motion.div)`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Inner = styled(motion.div)`
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
      flex-direction: column;
    }
`

const Left = styled(motion.div)`
  width: 70%;
  height: 100%;
  display: flex;
  align-items: space-around;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  @media (max-width: 600px) {
    width: 98%;
    padding: 20px 5px;
  }

`
const Right = styled(motion.div)`
  min-width: 30%;
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
`

const Set = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  align-items: space-around;
  justify-content: center;
  gap: 40px;
`

const H2 = styled(motion.div)`
    font-size: var(--fl);
    padding: 20px;
    width: max-content;
    color: #affc41;
    background: linear-gradient(to right, transparent 50%, #affc41 50%);
    background-size: 200% 100%;
    background-position: right;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center; 
    letter-spacing: 2px; 

    @media (max-width: 600px) {
      font-size: var(--fm);
      padding: 10px 20px;
      letter-spacing: 1px; 

`

const H5 = styled(motion.div)`
  display: flex;
  // align-items: center;
  // justify-content: center;
  color: white;
  padding: 20px 40px;
  flex-direction: column;
  line-height: 2;
  letter-spacing: 0.5px; 
  // background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  //       -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;

  @media (max-width: 600px) {
    padding: 10px;
  }


`

const Span = styled(motion.div)`
  padding: 5px;
  color: #d7e3fc;
  background: ${props => props.val};
  background-size: 200% 100%;
  background-position: right;
  display: flex;
  align-items: center;
  // justify-content: center; 
  background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 600px) {
    font-size: 12px;
  }

`

const Icon = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
`


const About1 = () => {


  return (
    <Container>
      <Inner>
        {/* <Parallax speed={0}> */}
        <Left>
          <H2
            initial={{ opacity: 0, backgroundColor: "#affc41", backgroundPosition: "100%" }}
            whileInView={{ opacity: 1, backgroundColor: "transparent", backgroundPosition: "0%" }}
            transition={{ ease: [0, .48, 0, .86], duration: 0.5, delay: 0.2 }}
          >
            About Us
          </H2>
          <H5>
            <Span>
              A passionate team of 4, experienced in business analysis and web development, who can help
              fix your problems one at a time.
            </Span>
            <br />
            <Span>
              We specialize in web development using latest technologies, and database management with MongoDB and PostgreSQL.
            </Span>
            <br />
            <Span>
              We also have expertise in analytics and data-driven decision making, and we use this expertise to help our clients make informed decisions based on their data.
            </Span>
            <br />
          </H5>
        </Left>
        {/* </Parallax> */}
        <Right>

          <Set>

            <Parallax speed={10}
              rotate={[-180, 0]}
              easing="easeInQuad"
            >
              <Icon>
                <img style={{
                  height: "80px", width: "80px", borderRadius: "5px", objectFit: "cover", objectPosition: "center"

                }} src={react} alt="loading..." />

              </Icon >
            </Parallax>

            <Parallax speed={12}

              easing="easeInQuad"
            >
              <Icon>
                <img style={{
                  height: "80px", width: "80px", borderRadius: "5px", objectFit: "cover", objectPosition: "center"

                }} src={html} alt="loading..." />
              </Icon >
            </Parallax>
          </Set>


          <Set>
            <Parallax speed={10}
              scale={[0.75, 1.5]}
              easing="easeInQuad"
            >
              <Icon>
                <img style={{
                  height: "80px", width: "80px", borderRadius: "5px", objectFit: "cover", objectPosition: "center"

                }} src={css} alt="loading..." />

              </Icon >
            </Parallax>

            <Parallax speed={12}
              easing="easeInQuad"
            >
              <Icon>
                <img style={{
                  height: "80px", width: "80px", borderRadius: "5px", objectFit: "cover", objectPosition: "center"

                }} src={js} alt="loading..." />
              </Icon >
            </Parallax>

          </Set>

          <Set>

            <Parallax speed={12}

              easing="easeInQuad"
            >
              <Icon>
                <img style={{
                  height: "80px", width: "80px", borderRadius: "5px", objectFit: "cover", objectPosition: "center"

                }} src={python} alt="loading..." />

              </Icon >
            </Parallax>

            <Parallax speed={10}
              scale={[0.75, 1.2]}
              easing="easeInQuad"
            >
              <Icon>
                <img style={{
                  height: "80px", width: "80px", borderRadius: "5px", objectFit: "cover", objectPosition: "center"

                }} src={chart} alt="loading..." />
              </Icon >
            </Parallax>

          </Set>

          <Set>
            <Parallax speed={12}

              easing="easeInQuad"
            >
              <Icon>
                <DiNodejs size={70} color={"#ee6c4d"} />
              </Icon >
            </Parallax>

            <Parallax speed={10}
              scale={[0.75, 1.5]}
              easing="easeInQuad"
            >
              <Icon>
                <RiFileExcel2Line size={60} color={"#007f5f"} />
              </Icon >
            </Parallax>
          </Set>


        </Right>
      </Inner>
    </Container >

  )
}

export default About1

