import styled, { keyframes } from "styled-components"
import { motion } from 'framer-motion'
import { Siddharth, Logesh, Shriram, Manoj } from "./People"
import { useState } from "react"


const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    color: white;

    @media (max-width: 600px) {
        flex-direction: column;
    }
`

const Tile = styled.div`
    width: 50%;
    height: 100%;
    padding: 20px;

    @media (max-width: 600px) {
        width: 98%;
        margin: auto;
        padding: 20px 5px;
    }

`
const Card = styled.div`
    width: 50%;
    height: 100%;

    @media (max-width: 600px) {
        width: 95%;
        margin: auto;
    }
`

const H2 = styled(motion.div)`
    font-size: var(--fl);
    padding: 20px;
    width: max-content;
    color: #affc41;
    margin-bottom: 30px;
    background: linear-gradient(to right, transparent 50%, #affc41 50%);
    background-size: 200% 100%;
    background-position: right;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center; 
    letter-spacing: 2px; 

    @media (max-width: 600px) {
        font-size: var(--fm);
        padding: 10px 20px;
        letter-spacing: 1px; 
    }
`

const Person = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 600px) {
        gap: 20px;
    }

`

const PersonCard = styled.div`
    height: 200px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    text-transform: uppercase;
    font-size: var(--fs);
    letter-spacing: 1px;
    cursor: pointer;
    border: ${props => props.border};
    border-radius: 3px;

    @media (max-width: 600px) {
        height: 120px;
        width: 120px;
        font-size: 10px;
    }

`

const rotate = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }

`

const Round = styled.div`
    height: 150%;
    width: 70%;
    background-image: ${props => props.color};
    opacity: 40%;
    position: absolute;
    transform: rotate(135deg);
    animation: ${rotate} 4s linear infinite;
`

const Content = styled.div`
    height: 99%;
    width: 99%;
    background-color: var(--bg);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #03045e;
    background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

const People1 = () => {

    const [selected, setSelected] = useState(1)


    return (
        <Container>
            <Tile>
                <H2
                    initial={{ opacity: 0, backgroundColor: "#affc41", backgroundPosition: "100%" }}
                    whileInView={{ opacity: 1, backgroundColor: "transparent", backgroundPosition: "0%" }}
                    transition={{ ease: [0, .48, 0, .86], duration: 0.5, delay: 0.2 }}
                >
                    People
                </H2>
                <Person>


                    <PersonCard onClick={() => setSelected(1)}
                        border={selected === 1 ? "1px solid #affc41" : ""}
                    >
                        <Round color={"linear-gradient(#00ccff, #d400d4)"} delay={"1s"} />
                        <Content>
                            Siddharth
                        </Content>
                    </PersonCard>

                    <PersonCard onClick={() => setSelected(2)}
                        border={selected === 2 ? "1px solid #affc41" : ""}
                    >
                        <Round color={"linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)"} delay={"2s"} />
                        <Content>
                            Logesh Kumar
                        </Content>
                    </PersonCard>

                    <PersonCard onClick={() => setSelected(4)}
                        border={selected === 4 ? "1px solid #affc41" : ""}
                    >
                        <Round color={"linear-gradient(to top, #d299c2 0%, #fef9d7 100%)"} delay={"3s"} />
                        <Content>
                            Manoj Kumar
                        </Content>
                    </PersonCard>

                    <PersonCard onClick={() => setSelected(3)}
                        border={selected === 3 ? "1px solid #affc41" : ""}
                    >
                        <Round color={"linear-gradient(135deg, #667eea 0%, #764ba2 100%)"} delay={"4s"} />
                        <Content>
                            Shriram
                        </Content>
                    </PersonCard>

                </Person>
            </Tile>
            <Card>
                {selected === 1 && <Siddharth />}
                {selected === 2 && <Logesh />}
                {selected === 3 && <Shriram />}
                {selected === 4 && <Manoj />}
            </Card>
        </Container>
    )
}

export default People1