import styled from "styled-components"
// import image1 from "../Assets/logo/1.png"
// import image2 from "../Assets/logo/2.png"
import image3 from "../Assets/logo/4.png"
import { useState } from "react"
import { useEffect } from "react"


const Container = styled.div`
    height: 100%;
    width: 90%;
    margin: auto;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 600px) {
        flex-direction: column;
        justify-content: space-around;
        gap: 20px;
    }

`

const Logo = styled.div`
`

const Contact = styled.div`
    border: 1px solid #affc41;
    padding: 20px;
    border-radius: 3px;
    cursor: pointer;
    color: #affc41;
    letter-spacing: 1px;

    @media (max-width: 600px) {
        font-size: 12px;
        letter-spacing: 0.5px;
    }
`

const Copied = styled.div`
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fefae0;
`

const Contacts = () => {

    const [width, setWidth] = useState("100px")
    const [copied, setCopied] = useState(false)


    useEffect(() => {
        if (window.innerWidth < 600) {
            setWidth("80px")
        }

    }, [])

    const Clicked = async () => {
        await navigator.clipboard.writeText('logesh.k@magecorp.in')
        setCopied(true)
    }

    useEffect(() => {

        if (copied) {
            setTimeout(() => {
                setCopied(false)
            }, 2000)
        }
    }, [copied])

    return (
        <Container id="contact">
            <Logo>
                <img style={{
                    height: width, width: width, borderRadius: "10px", objectFit: "contain", objectPosition: "center",

                }} src={image3} alt="loading..." />
            </Logo>

            {copied &&
                <Copied>
                    ID Copied
                </Copied>
            }

            <Contact onClick={() => Clicked()}>
                contact us @  // sales@magecorp.in //
            </Contact>
        </Container>
    )
}

export default Contacts