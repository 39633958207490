import React from 'react'
import styled from 'styled-components'
import Logeshimg from "../Assets/people/Logesh.png"
import Siddharthimg from "../Assets/people/Siddharth.png"
import Shriramimg from "../Assets/people/Shriram.png"
import Manojimg from "../Assets/people/Manoj.png"
import { motion } from "framer-motion"
import { useState } from 'react'
import { useEffect } from 'react'

const Container = styled(motion.div)`
    height: 100%;
    width: 100%;
    color: var(--c1);
    letter-spacing: var(--ls);
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 600px) {
        letter-spacing: 0.7px;
    }

`

const Main = styled(motion.div)`
    height: 170px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: var(--fm);
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 600px) {
        font-size: 12px;
        line-height: 2;
    }

`

const Info = styled(motion.div)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: var(--fs);
    line-height: 2;

    @media (max-width: 600px) {
        font-size: 12px;
    }
`

const Heading = styled(motion.div)`
    font-size: var(--fm);
    color: var(--c2);
    
    @media (max-width: 600px) {
        font-size: 12px;
    }
`
const Content = styled(motion.div)`
    padding: 10px 30px;
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 600px) {
        font-size: 12px;
        padding: 10px;
    }

`


const Siddharth = () => {

    const [height, setHeight] = useState("150px")



    useEffect(() => {
        if (window.innerWidth < 600) {
            setHeight("100px")
        }
    }, [])

    return (
        <Container
        >
            <Main
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.2 }}
            >
                Retail Consultant | Strategy
                <img style={{
                    height: height, width: height, backgroundColor: "#caf0f8", borderRadius: "50%", objectFit: "cover", objectPosition: "center",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"

                }} src={Siddharthimg} alt="loading..." />
            </Main>

            <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.2 }}
            >

                <Info>
                    <Heading>
                        Companies Worked
                    </Heading>
                    <Content>
                        Ex-Head | Business Intelligence | Soch Apparels
                        <br />
                        Ex-Head | Business Intelligence | Go Colors
                        <br />
                        Ex-Business Development | Coffee Day
                    </Content>
                </Info>
                <Info>
                    <Heading>
                        Education
                    </Heading>
                    <Content>
                        MS-Analytics | University of Buffalo, USA
                        <br />
                        MBA | Amrita School of Business, Bangalore
                        <br />
                        Engineering | GCE, Tirunelveli
                    </Content>
                </Info>
                <Info>
                    <Heading>
                        Skills
                    </Heading>
                    <Content>
                        Analytics | Leadership | Market Research
                    </Content>
                </Info>
            </motion.div>
        </Container>
    )
}


const Logesh = () => {


    const [height, setHeight] = useState("150px")



    useEffect(() => {
        if (window.innerWidth < 600) {
            setHeight("100px")
        }
    }, [])


    return (
        <Container
        >
            <Main
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.2 }}
            >
                Full Stack Developer | Analytics
                <img style={{
                    height: height, width: height, backgroundColor: "#c7f9cc", borderRadius: "50%", objectFit: "cover", objectPosition: "center",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"

                }} src={Logeshimg} alt="loading..." />
            </Main>
            <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.2 }}
            >

                <Info>
                    <Heading>
                        Companies Worked
                    </Heading>
                    <Content>
                        Senior Manager | Business Intelligence | Go Colors
                        <br />
                        Ex-Engineer | Aquity Solutions
                    </Content>
                </Info>
                <Info>
                    <Heading>
                        Education
                    </Heading>
                    <Content>
                        Engineering | GCE, Tirunelveli
                    </Content>
                </Info>
                <Info>
                    <Heading>
                        Skills
                    </Heading>
                    <Content>
                        Reactjs | Javascript | NodeJS | Full Stack | GCP | MongoDB
                    </Content>
                </Info>
            </motion.div>

        </Container>
    )
}



const Shriram = () => {

    const [height, setHeight] = useState("150px")



    useEffect(() => {
        if (window.innerWidth < 600) {
            setHeight("100px")
        }
    }, [])

    return (
        <Container
        >
            <Main
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.2 }}
            >
                Python Developer | CRM
                <img style={{
                    height: height, width: height, backgroundColor: "#cad2c5", borderRadius: "50%", objectFit: "cover", objectPosition: "center",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"

                }} src={Shriramimg} alt="loading..." />
            </Main>
            <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.2 }}
            >

                <Info>
                    <Heading>
                        Companies Worked
                    </Heading>
                    <Content>
                        Business Analyst | Kushal's Fashion
                        <br />
                        Ex-Analyst | Go Colors
                    </Content>
                </Info>
                <Info>
                    <Heading>
                        Education
                    </Heading>
                    <Content>
                        Engineering | GCE, Tirunelveli
                    </Content>
                </Info>
                <Info>
                    <Heading>
                        Skills
                    </Heading>
                    <Content>
                        Python | PostgreSQL | Scripts | Excel
                    </Content>
                </Info>
            </motion.div>

        </Container>
    )
}



const Manoj = () => {

    const [height, setHeight] = useState("150px")



    useEffect(() => {
        if (window.innerWidth < 600) {
            setHeight("100px")
        }
    }, [])

    return (
        <Container
        >
            <Main
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.2 }}
            >
                Automation Specialist | DB
                <img style={{
                    height: height, width: height, backgroundColor: "#fdffb6", borderRadius: "50%", objectFit: "cover", objectPosition: "center",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"

                }} src={Manojimg} alt="loading..." />
            </Main>
            <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.2 }}
            >
                <Info>
                    <Heading>
                        Companies Worked
                    </Heading>
                    <Content>
                        Manager | IT Automation | Sugar Cosmetics
                        <br />
                        Ex-Engineer | IT Automation | Kushal's Fashion
                        <br />
                        Ex-Manager | Business Intelligence | Go Colors
                    </Content>
                </Info>
                <Info>
                    <Heading>
                        Education
                    </Heading>
                    <Content>
                        Engineering | GCE, Tirunelveli
                    </Content>
                </Info>
                <Info>
                    <Heading>
                        Skills
                    </Heading>
                    <Content>
                        MYSQL | Excel | Pandas | Python | Analytics
                    </Content>
                </Info>
            </motion.div>
        </Container>
    )
}

export { Siddharth, Logesh, Shriram, Manoj }