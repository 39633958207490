import React from "react"
import styled, { keyframes } from "styled-components"
import { motion } from "framer-motion"
import { Canvas } from "@react-three/fiber"
import { OrbitControls, Stage } from "@react-three/drei"
import Planes from "../Components/Planes"
import { useState } from "react"
import { useEffect } from "react"
// import image1 from "../Assets/logo/1.png"
import image2 from "../Assets/logo/4.png"
// import image3 from "../Assets/logo/3.png"
import Projects from "../Components/Projects"
import About1 from "../Components/About1"
import Brands1 from "../Components/Brands1"
import People1 from "../Components/People1"
import Contacts from "../Components/Contacts"
import Services from "../Components/Services"



const Container = styled(motion.div)`
    max-width: 100vw;
    overflow: hidden;
    background-color: var(--bg);
`

const Section = styled(motion.div)`
    min-height: ${props => props.height};
    width: 100%;
    display: flex;
    position: relative;
    background-color: ${props => props.bg};
`
const Section1 = styled(motion.div)`
    height: ${props => props.height};
    width: 100%;
    display: flex;
    position: relative;
`
const Front = styled(motion.div)`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    letter-spacing: 2px;
    position: absolute;
    z-index: 10;
`

const Back = styled(motion.div)`    
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    letter-spacing: 2px;
    position: absolute;
`

const Plane = styled(motion.div)`
    right: 0;
    bottom: 0;
    position: absolute;
    height: 350px;
    width: 500px;
    // z-index: 1000;
`

const Contact = styled(motion.div)`
    color: #affc41;
    z-index: 100;
    position: absolute;
    right: 0;
    top: 0;
    margin: 50px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: var(--fxs);
    border: 1px solid #affc41;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 2px;

    @media (max-width: 600px) {
      margin: 30px 10px;
      font-size: 9px;
      letter-spacing: 0.8px;
     }

`
const Logo = styled(motion.div)`
    z-index: 100;
    position: absolute;
    left: 0;
    top: 0;
    margin: 30px;

    @media (max-width: 600px) {
      margin: 10px;
     }
`

const Scroll = styled(motion.div)`
    color: #affc41;
    z-index: 100;
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 50px 100px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: var(--fxs);
    border: 1px solid #affc41;
    padding: 10px 50px;
    cursor: pointer;
    border-radius: 2px;


    @media (max-width: 600px) {
      font-size: 9px;
      left: 50%;
      bottom: 80px;
      padding: 15px;
      transform: translate(-50%, -50%);
      margin: 0 auto;
      letter-spacing: 0.8px;
    }

`

const makeItfadeIn = keyframes`
    100% {
        background-position: left;
    }
`

const glitch1 = keyframes`
0% {
    clip: rect(132px, auto, 101px, 30px);
  }
  5% {
    clip: rect(17px, auto, 94px, 30px);
  }
  10% {
    clip: rect(40px, auto, 66px, 30px);
  }
  15% {
    clip: rect(87px, auto, 82px, 30px);
  }
  20% {
    clip: rect(137px, auto, 61px, 30px);
  }
  25% {
    clip: rect(34px, auto, 14px, 30px);
  }
  30% {
    clip: rect(133px, auto, 74px, 30px);
  }
  35% {
    clip: rect(76px, auto, 107px, 30px);
  }
  40% {
    clip: rect(59px, auto, 130px, 30px);
  }
  45% {
    clip: rect(29px, auto, 84px, 30px);
  }
  50% {
    clip: rect(22px, auto, 67px, 30px);
  }
  55% {
    clip: rect(67px, auto, 62px, 30px);
  }
  60% {
    clip: rect(10px, auto, 105px, 30px);
  }
  65% {
    clip: rect(78px, auto, 115px, 30px);
  }
  70% {
    clip: rect(105px, auto, 13px, 30px);
  }
  75% {
    clip: rect(15px, auto, 75px, 30px);
  }
  80% {
    clip: rect(66px, auto, 39px, 30px);
  }
  85% {
    clip: rect(133px, auto, 73px, 30px);
  }
  90% {
    clip: rect(36px, auto, 128px, 30px);
  }
  95% {
    clip: rect(68px, auto, 103px, 30px);
  }
  100% {
    clip: rect(14px, auto, 100px, 30px);
  }
`
const glitch2 = keyframes`
0% {
    clip: rect(129px, auto, 36px, 30px);
  }
  5% {
    clip: rect(36px, auto, 4px, 30px);
  }
  10% {
    clip: rect(85px, auto, 66px, 30px);
  }
  15% {
    clip: rect(91px, auto, 91px, 30px);
  }
  20% {
    clip: rect(148px, auto, 138px, 30px);
  }
  25% {
    clip: rect(38px, auto, 122px, 30px);
  }
  30% {
    clip: rect(69px, auto, 54px, 30px);
  }
  35% {
    clip: rect(98px, auto, 71px, 30px);
  }
  40% {
    clip: rect(146px, auto, 34px, 30px);
  }
  45% {
    clip: rect(134px, auto, 43px, 30px);
  }
  50% {
    clip: rect(102px, auto, 80px, 30px);
  }
  55% {
    clip: rect(119px, auto, 44px, 30px);
  }
  60% {
    clip: rect(106px, auto, 99px, 30px);
  }
  65% {
    clip: rect(141px, auto, 74px, 30px);
  }
  70% {
    clip: rect(20px, auto, 78px, 30px);
  }
  75% {
    clip: rect(133px, auto, 79px, 30px);
  }
  80% {
    clip: rect(78px, auto, 52px, 30px);
  }
  85% {
    clip: rect(35px, auto, 39px, 30px);
  }
  90% {
    clip: rect(67px, auto, 70px, 30px);
  }
  95% {
    clip: rect(71px, auto, 103px, 30px);
  }
  100% {
    clip: rect(83px, auto, 40px, 30px);
  }
`

const H1 = styled(motion.div)`
    position: relative;
    font-weight: 700;
    font-size: ${props => props.fs};
    padding: 30px;
    text-transform: uppercase;
    width: max-content;
    text-shadow: -2px 0 red;
    color: #f1faee;
    align-text: left;

    &:before {
        padding: 30px;
        content: attr(data-text);
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        top: 0;
        left: 1px;
        text-shadow: 2px 0 blue;
        animation: ${glitch1} 3s linear infinite reverse;

    }

    &:after {
        padding: 30px;
        content: attr(data-text);
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        top: 0;
        left: -1px;
        text-shadow: -2px 0 red;
        animation: ${glitch2} 3s linear infinite reverse;
    }

    @media (max-width: 600px) {
      font-size: var(--fm);
     }
`
const H2 = styled(motion.div)`
    font-size: var(--fm);
    padding: 15px 30px;
    color: #ffc300;
    margin: 5px 30px;
    padding: 20px;
    width: max-content;
    background: linear-gradient(to right, transparent 50%, #ffc300 50%);
    background-size: 200% 100%;
    background-position: right;
    text-transform: uppercase;
    background: linear-gradient(to right, #b12a5b 0%, #ff867a 15%, #ff8c7f 5%, #f99185 70%, #cf556c 85%, #b12a5b 100%);;
    background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${makeItfadeIn} 1s 0.5s forwards cubic-bezier(0,.48,0,.86);

    @media (max-width: 600px) {
      font-size: 10px;
      letter-spacing: 1px;
     }
`


const H5 = styled(motion.div)`
    height: 20px;
    width: 60%;
    background-color: green;
    display: flex;
    align-items: center;
    background: linear-gradient(to right, #b12a5b 0%, #ff867a 15%, #ff8c7f 5%, #f99185 70%, #cf556c 85%, #b12a5b 100%);;
    background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

const Circle = styled.div`
     height: ${props => props.scale};
     width: ${props => props.scale};
     background-color: var(--bg);
     border-radius: 50%;
     position: absolute;
     transition: height 0.1s cubic-bezier(.17,.67,.92,.48), width 0.1s cubic-bezier(.17,.67,.92,.48);
`

const Homepage2 = () => {

  const [scale, setScale] = useState(900)
  const [width, setWidth] = useState("100px")



  useEffect(() => {
    if (window.innerWidth < 600) {
      setScale(550)
      setWidth("80px")
    }

  }, [])



  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (zoom < scale) {
  //       setZoom(zoom + 50);
  //     }
  //   }, 1);

  //   return () => {
  //     clearTimeout(timeout);
  //   };


  // }, [scale, zoom]);

  const clicked = () => {
    window.location.replace("/#contact")
  }

  return (

    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
    >

      <Logo
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2, delay: 0.8 }}
      >
        <img style={{
          height: width, width: width, borderRadius: "10px", objectFit: "contain", objectPosition: "center",

        }} src={image2} alt="loading..." />
      </Logo>

      <Section height={"100vh"} bg={"#390099"}>
        <Front>
          <H5
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ type: "spring", stiffness: 100, duration: 0.5, delay: 0.2 }}
          >
            Turn your
          </H5>

          <H1 data-text="paperballs to PaperPlanes" fs={"45px"}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ type: "spring", stiffness: 100, duration: 0.5, delay: 0.5 }}
          >
            paperballs to PaperPlanes
          </H1>

          <H2
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ type: "spring", stiffness: 100, duration: 0.5, delay: 0.8 }}
          >
            Consultants | Web Designers | Analytics
          </H2>

        </Front>

        <Back>
          <Circle scale={scale + "px"} />
          {/* <Canvas>
            <mesh>
              <OrbitControls enableZoom={false} />
              <ambientLight intensity={1} />
              <directionalLight position={[2, 5, 10]} />
              <Ring args={[zoom, 20, 200]} scale={1} > */}
          {/* <meshBasicMaterial>
                                        <GradientTexture
                                            stops={[0, 1]} // As many stops as you want
                                            colors={['aquamarine', 'hotpink']} // Colors need to match the number of stops
                                            size={1024} // Size is optional, default = 1024
                                        />
                                    </meshBasicMaterial> */}
          {/* <MeshDistortMaterial color="#390099" attach="material" distort={0} speed={1} />
              </Ring>
            </mesh>
          </Canvas> */}
        </Back>

        {/* <Plane>
          <Canvas>
            <OrbitControls enableZoom={false} autoRotate={true} />
            <Stage environment="city" intensity={0.3}>
              <Planes />
            </Stage>
          </Canvas>
        </Plane> */}

        <Contact onClick={() => clicked()}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ type: "spring", stiffness: 100, duration: 0.5, delay: 1.2 }}
        >
          Contact Us
        </Contact>

        <Scroll
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ type: "spring", stiffness: 100, duration: 0.5, delay: 1.5 }}
        >
          Scroll to know more
        </Scroll>

      </Section>

      <Section height={"100vh"}>
        <Projects />
      </Section>

      <Section height={"100vh"}>
        <About1 />
      </Section>

      <Section1 height={"40vh"}>
        <Brands1 />
      </Section1>

      <Section height={"80vh"}>
        <Services />
      </Section>

      <Section height={"100vh"}>
        <People1 />
      </Section>


      <Section1 height={"40vh"}>
        <Contacts />
      </Section1>

    </Container>
  )
}

export default Homepage2