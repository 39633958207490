import { createContext, useState } from "react";


export const DataContext = createContext()

const AuthContextProvider = (props) => {

    const [selected, setSelected] = useState(1);

    return (
        <DataContext.Provider value={{ selected, setSelected }}>
            {props.children}
        </DataContext.Provider>
    )
}

export default AuthContextProvider;
