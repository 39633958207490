import styled from 'styled-components'
import { motion } from "framer-motion"
import image1 from "../Assets/vendor/6.png"
import image2 from "../Assets/vendor/1.png"
import image3 from "../Assets/vendor/7.png"
import image4 from "../Assets/vendor/8.png"
import { useContext } from 'react'
import { DataContext } from '../Contexts/DataContext'
import { Parallax } from 'react-scroll-parallax'
import { useState } from 'react'
import { useEffect } from 'react'


const Project = styled(motion.div)`
    width: 80%;
    display: flex;
    align-items: center;
    // justify-content: center;
    margin: auto;
    min-height: 100%;
    flex-direction: column;

    @media (max-width: 600px) {
        width: 90%;
       }

`

const Header = styled(motion.div)`
    padding: 30px;
    text-transform: uppercase;
    background: linear-gradient(to right, #b12a5b 0%, #ff867a 15%, #ff8c7f 5%, #f99185 70%, #cf556c 85%, #b12a5b 100%);;
    background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 1.5px;

    @media (max-width: 600px) {
        font-size: var(--fs);
        line-height: 1.5;
        letter-spacing: 0.5px;
        text-transform: capitalize;
        padding: 5px;
       }
  
`

const Body = styled(motion.div)`
    height: 100%;
    width: 100%;
    display: flex;
    // align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    gap: 20px;
`

const H5 = styled(motion.div)`
    text-transform: uppercase;
    margin: 20px;
    background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 1px;

    @media (max-width: 600px) {
        font-size: var(--fs);
        letter-spacing: 0.5px;
       }

`
const Para = styled(motion.div)`
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-indent: 50px;
    line-height: 2;
    letter-spacing: 0.5px;

    @media (max-width: 600px) {
        font-size: var(--fs);
        letter-spacing: 0.5px;
        text-indent: 20px;
        font-size: 12px;
    }

`
const Next = styled(motion.div)`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`
const Button = styled(motion.div)`
    background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);    color: black;
    width: max-content;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;

    @media (max-width: 600px) {
        font-size: var(--fs);
        font-size: 10px;
    }

`

const Image = styled(motion.div)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Project1 = () => {

    const { setSelected } = useContext(DataContext)
    const [height, setHeight] = useState("600px")
    const [width, setWidth] = useState("1000px")
    const [speed, setSpeed] = useState(20)



    useEffect(() => {
        if (window.innerWidth < 600) {
            setHeight("250px")
            setWidth("400px")
            setSpeed(10)
        }
    }, [])


    const changeSelected = () => {
        setSelected(2)
        window.location.replace("/#projects")
    }


    return (
        <Project>
            <Header>
                1. Vendor and SKU Management Tool
            </Header>
            <Body>

                <Parallax
                    speed={10}
                    scale={[0.75, 1]}
                    easing="easeInQuad"
                >

                    <Image>
                        <img style={{
                            height: height, width: width, borderRadius: "10px", objectFit: "contain", objectPosition: "center",

                        }} src={image1} alt="loading..." />
                    </Image>

                </Parallax>


                <H5
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    Client
                </H5>

                <Para
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    Rubans Accessories is a 40 Cr turnover fashion jewelry brand (Known for Shark Tank Appearance in 2023 season)
                </Para>

                <H5
                    initial={{ opacity: 0, x: 20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    Requirement
                </H5>
                <Para
                    initial={{ opacity: 0, x: 20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    The brand needed a tool which helps them track the SKUs that are pending under different stages
                </Para>

                <Parallax
                    speed={speed}
                    scale={[0.75, 1]}
                    easing="easeInQuad"
                >

                    <Image>
                        <img style={{
                            height: height, width: width, borderRadius: "10px", objectFit: "contain", objectPosition: "center",

                        }} src={image2} alt="loading..." />
                    </Image>

                </Parallax>

                <H5
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    Tech Stack
                </H5>
                <Para
                    initial={{ opacity: 0, x: 20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    React | MongoDB | Google Cloud Platform | Nodejs
                </Para>

                <Parallax
                    translateX={[20, -50]}
                    scale={[0.75, 1]}
                    easing="easeInQuad"
                >

                    <Image>
                        <img style={{
                            height: height, width: width, borderRadius: "10px", objectFit: "contain", objectPosition: "center",

                        }} src={image3} alt="loading..." />
                    </Image>

                </Parallax>


                <Parallax
                    translateX={[-20, 50]}
                    scale={[0.75, 1]}
                    easing="easeInQuad"
                >

                    <Image>
                        <img style={{
                            height: height, width: width, borderRadius: "10px", objectFit: "contain", objectPosition: "center",

                        }} src={image4} alt="loading..." />
                    </Image>

                </Parallax>

                <Next onClick={() => changeSelected()}>
                    <Button>
                        Next: Store Learning App
                    </Button>
                </Next>

            </Body>
        </Project >
    )
}

export default Project1